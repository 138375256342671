import React from "react"
import SEO from "../../../components/seo"
// import { Helmet } from 'react-helmet'
import { Link } from "gatsby"
import TwoColumn from "../../../components/two-column"
import Layout from "../../../components/layout"
import Lifecycle from "../../../components/lifecycle"
import {lifecycleContent} from "../../../lib/lifecycle-content"
import List from "../../../components/compare-list"
import PlanActionInAction from "../../../components/plan-action-in-action"
import Gateways from "../../../components/gateways"

const bannerContent = {
  title: "Keep all of your tools and systems connected and up-to-date.",
  description: [
    {
      text: "When a customer signs up, Billsby does the rest. Add them to your newsletter, update your CRM, send them a text message or activate a SIM - even send them a parcel or thank you card! It's your entire subscription workflow done for you."
    }
  ],
  buttons: [
    {
      url: 'https://app.billsby.com/registration',
      buttonColor: 'orange',
      buttonText: 'Sign up today',
      isExternal: true,
    },
    {
      scheduleButton: true,
    }
  ],
  tags: [
    {
      class: 'adoric_roadmap btn-coming-soon',
      text: "COMING SOON",
      iconDisable: true,
    }
  ],
  isBanner: true,
  sectionPlanActionInAction: true,
  image: require('../../../images/plan-action-in-action.svg'),
  svg: true,
  imageName: 'plan-action svg',
  backgroundColor: '#243F5C',
  textColor: '#fff',
  imagePosition: 'right',
}

const bannerContent2 = {
  title: "Save time and money on custom development",
  description: [
    {
      text: "Because Plan Actions are 'done-for-you', there's no need to worry about the time and expense of custom development to integrate tools and systems - so you'll be up and running faster."
    },
    {
      text: "Plus, just as importantly, Plan Actions empower you to say yes to internal stakeholders more often, because their preferred tool is probably already integrated with Billsby through either Plan Actions or Zapier."
    }
  ],
  sectionPlanActionInAction: true,
  image: 'money.png',
  svg: false,
  // srcSet: 'chain@2x.png 2x',
  imageName: 'money svg',
  backgroundColor: '#243F5C',
  textColor: '#fff',
  imagePosition: 'right',
}

const listContent = {
  bgColor: '',
  sectionPlanAction: true,
  content: [
    {
      title: "Align your tools, systems and processes around billing journeys.",
      image: require('../../../images/billing-journey.svg'),
      svg: true,
      list: [
        {
          subtitle: "Keep systems in the loop",
          text: "Trigger updates across your system when customers billing account status changes, so that they're always up-to-date."
        },
        {
          subtitle: "Suspend access immediately",
          text: "When customers don't pay, lock them out of your systems immediately. Then automatically reinstate access once they're back on track."
        },
        {
          subtitle: "No development required",
          text: "All of this works with no code - just drag, drop and click in the Billsby control panel to change your Plan Action workflows."
        },
      ]
    },
  ]
}

const planActionContent = {
  title: "Plan Actions in action",
  action: [
    {
      image: require("../../../images/emma.svg"),
      alt: "Emma",
      heading: "This is Emma",
      text: "She's just signed up for a free trial account on your website",
      detailReverse: true
    },
    {
      image: require("../../../images/freetrial.svg"),
      alt: "freetrial",
      heading: "This triggers your 'Free Trial' plan actions",
      text: "One of which is to sign her up to your Prospects newsletter in MailerLite",
      detailReverse: false
    },
    {
      image: require("../../../images/firstpayment.svg"),
      alt: "firstpayment",
      heading: "Her first payment goes through on-time",
      text: "And Plan Actions automatically moves her to your Basic subscribers newsletter",
      detailReverse: true
    },
    {
      image: require("../../../images/pause.svg"),
      alt: "pause",
      heading: "A few months later, Emma's payment fails",
      text: "Because her plan is suspended, she's removed from the newsletter",
      detailReverse: false
    },
    {
      image: require("../../../images/resume.svg"),
      alt: "resume",
      heading: "Once the issue is resolved her plan resumes ",
      text: "Alongside it, Plan Actions adds her back to the Basic subscribers newsletter",
      detailReverse: true
    },
    {
      image: require("../../../images/delete.svg"),
      alt: "delete",
      heading: "A little while later, Emma cancels her plan ",
      text: "So Plan Actions moves her to your Ex-Subscribers mailing list for retargeting",
      detailReverse: false
    },
  ]
}

const gatewaysContent = {
  title: "Plan Actions are coming soon",
  text: <>But here are some of the services we've got on our integration roadmap. Whilst you wait, you can always integrate using our <Link to="/product/integrations/api-and-web-hooks">Custom API</Link> or request integrations from our <Link to="/pro/development">Pro Development</Link> team.</>,
  backgroundColor: "#fff",
  textColor: "",
  isUnderConstruction: true,
  block: [
    {
      img: require('../../../images/mailchimp.svg'),
      svg: true,
      brandName: "mailchimp",
    },
    {
      img: require('../../../images/mailerlite.svg'),
      svg: true,
      brandName: "mailerlite",
    },
    {
      img: require('../../../images/hubspot.svg'),
      svg: true,
      brandName: "hubspot",
    },
    {
      img: require('../../../images/pipedrive.svg'),
      svg: true,
      brandName: "pipedrive",
    },
    {
      img: require('../../../images/calendly.svg'),
      svg: true,
      brandName: "calendly",
    },
    {
      img: require('../../../images/freshdesk.svg'),
      svg: true,
      brandName: "freshdesk",
    },
    {
      img: require('../../../images/asana.svg'),
      svg: true,
      brandName: "asana",
    },
    {
      img: require('../../../images/twilio.svg'),
      svg: true,
      brandName: "twilio",
    },
    {
      img: require('../../../images/zendesk.svg'),
      svg: true,
      brandName: "zendesk",
    },
    {
      img: require('../../../images/typeform.svg'),
      svg: true,
      brandName: "typeform",
    },
    {
      img: require('../../../images/amazon.svg'),
      brandName: "amazon",
      svg: true,
    },
    {
      img: require('../../../images/freshdesk.svg'),
      svg: true,
      brandName: "freshdesk",
    },
    {
      img: require('../../../images/handwrytten.svg'),
      svg: true,
      brandName: "handwrytten",
    },
    {
      img: require('../../../images/google.svg'),
      svg: true,
      brandName: "google",
    },
    {
      img: require('../../../images/freshdesk.svg'),
      svg: true,
      brandName: "freshdesk",
    },
  ]
}

const planAction = () => {
  return(
    <Layout className="plan-action">
      <SEO 
        title="Plan Actions | Billsby | Powerful, customizable subscription billing software" 
        description="Integrate your subscription billing and recurring payments with other tools from across your business." 
        url="https://www.billsby.com/product/integrations/planactions"
      />

      <TwoColumn content={bannerContent}/>
      <List compare={listContent}/>
      <Lifecycle content={lifecycleContent} />
      <PlanActionInAction data={planActionContent}/>
      <TwoColumn content={bannerContent2}/>
      <Gateways content={gatewaysContent} />
    </Layout>
  )
}

export default planAction